.teaser-cards-block {
    .block-padding-top & {
        padding-top: var(--block-padding-top);
    }

    .block-padding-bottom & {
        padding-bottom: var(--block-padding-bottom);
    }

    display: flex;
    flex-direction: column;
    color: var(--text-color);

    .teaser-cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 1rem;
        margin-top: 1rem;

        @include media(">small", "<large") {
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        }

        @include media("<small") {
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
        }

        .teaser-card {
            background-color: var(--reverse-background-color);
            color: var(--reverse-text-color);
            padding-top: 3rem;
            padding-bottom: 3rem;
            padding-left: 2rem;
            padding-right: 2rem;
            height: 100%;
            width: 100%;

            @include media("<small") {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            .title {
                min-height: 4rem;
                display: flex;
                align-items: flex-start;

                @include media("<800px") {
                    min-height: 0;
                }

                @include media("<small") {
                    min-height: 0;
                }

                h1 {
                    font-size: 1.3rem;
                    font-weight: 700;
                    letter-spacing: var(--text-letter-spacing);
                }
            }

            >h2 {
                margin-bottom: 0.3rem;
                font-weight: 200;
                font-family: "Open Sans", sans-serif;
                letter-spacing: var(--text-letter-spacing);
            }

            >.teaser-card-seperator {
                content: "";
                display: block;
                width: 30%;
                max-width: 6rem;
                border-bottom: solid 4px;
                border-radius: 5px;
                border-color: var(--secondary-bg-color);
                margin-bottom: 1.5rem;
            }

            >p {
                >strong {
                    font-weight: 600;
                }

                line-height: 1.3rem;
                padding-bottom: 1rem;
                font-weight: 200;
            }
        }
    }
}
