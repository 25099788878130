@mixin max-width-container {
    display: flex;
    justify-content: center;
}

@mixin max-width-container-inner {
    width: 100%;
    max-width: var(--site-max-width);
    padding-right: var(--site-outer-gap);
    padding-left: var(--site-outer-gap);
}

@mixin max-width-container-no-padding {
    width: 100%;
    max-width: var(--site-max-width);
}

.max-width-container {
    @include max-width-container;
}
