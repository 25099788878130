$bg-data: "../../menu_background.svg";

.menu-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 100;

    .menu-fixed-container {
        position: fixed;
        width: 100%;
        pointer-events: none;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        overflow: hidden;

        * {
            pointer-events: all;
        }
    }

    .menu-btn {
        background-color: transparent;
        position: absolute;
        top: 0;
        right: 0;
        color: var(--text-color-light);
        border: none;
        padding: 1rem;
        z-index: 100;
        cursor: pointer;

        .bar1,
        .bar2,
        .bar3 {
            display: block;
            width: 2rem;
            height: 0.4rem;
            background-color: var(--text-color-light);
            margin-bottom: 0.3rem;
            transition: 0.4s;
            border: solid 0.01rem;
            border-radius: 5px;
        }

        .menu-open & .bar1 {
            transform: rotate(-45deg) translate(-0.6rem, 0.5rem);
        }

        .menu-open & .bar2 {
            opacity: 0;
        }

        .menu-open & .bar3 {
            transform: rotate(45deg) translate(-0.5rem, -0.4rem);
        }
    }

    nav#menu {
        &::before {
            content: "";
            position: absolute;
            top: -10%;
            right: -8%;
            width: 105%;
            height: 120%;
            background-color: #2b2e34;
            pointer-events: none;
            z-index: -1;
            transform: skew(-10deg, 10deg);

            .loaded & {
                transition: all 0.2s ease-in-out;
                transition-delay: 0.1s;
            }

            .menu-open & {
                top: -10%;
                right: -10%;
                transform: skew(-10deg, 10deg);
                transition-delay: 0.1s;
            }
        }

        transform: translate(calc(100% - 4rem), calc(-100% + 4rem));

        .loaded & {
            transition: transform 0.1s ease-in-out;
            animation-fill-mode: forwards;
            transition-delay: 0.2s;
        }

        .menu-open & {
            transform: translate(0%, 0%);
            animation-fill-mode: forwards;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-color-light);
        padding: 4rem;
        margin-bottom: 4rem;
        padding-top: 3rem;

        ul {
            li {
                font-weight: bold;
                font-size: 1.3rem;
                margin-top: 0.8rem;
                margin-left: 3px;
                padding-left: 0.3rem;
                opacity: 0;

                .loaded & {
                    transition: opacity 0.2s ease-out;
                    transition-delay: 0.2s;
                }

                .menu-open & {
                    opacity: 1;
                    transition-delay: 0.4s;
                }

                a {
                    // font-size: 1.4rem;
                    padding-left: 1rem;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        height: 100%;
                        top: 0;
                        border-right: 0.4rem solid #21688b;
                        transition: all 0.2s ease-in-out;
                        opacity: 0;
                    }

                    &.is-active,
                    &.is-active-parent {
                        &::before {
                            border-right: 0.2rem solid #21688b;
                            opacity: 1;
                        }
                    }

                    &:hover {
                        &::before {
                            border-right: 0.4rem solid #21688b;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
