.limage-text {
    .block-padding-top & {
        padding-top: 1rem;
    }

    .block-padding-bottom & {
        padding-bottom: 1rem;
    }

    padding-left: 0;
    padding-right: 0;
    color: var(--text-color);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    .text-box {
        background-color: var(--secondary-bg-color);
        color: var(--text-color-light);
        grid-column: 2 / span 2;
        grid-row: 1 / 1;
        padding: 2rem;

        @include media("<small") {
            grid-column: 1 / span 3;
            padding-right: calc(var(--site-outer-gap) + var(--extra-mobile-padding));
            padding-left: calc(var(--site-outer-gap) + var(--extra-mobile-padding));
        }

        .title {
            font-size: 1.8rem;
            font-weight: bold;
            margin-bottom: 1rem;

            @include media("<small") {
                font-size: 1.6rem;
                line-height: 1.2;
            }
        }

        .text {
            @include texteditor-default;
        }
    }

    .image-container {
        display: flex;
        width: 100%;
        height: 100%;

        @include media("<small") {
            height: 120px;
            width: 100%;
        }

        >img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
    }

    .image-left {
        grid-column: 1 / 1;
        grid-row: 1 / span 2;

        @include media("<small") {
            grid-area: 2 / 1 / 2 / 1;
            padding-left: 1rem;
        }
    }

    .image-bottom-middle {
        grid-area: 2 / 2 / 2 / 2;
    }

    .image-bottom-right {
        grid-area: 2 / 3 / 2 / 3;

        @include media("<small") {
            padding-right: 1rem;
        }
    }
}
