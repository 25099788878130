.tech-preview-container {
    @include max-width-container;

    background-color: var(--dark-color-lighter);
}

.tech-preview {
    @include max-width-container-inner;

    display: flex;
    flex-direction: column;
    color: var(--text-color-light);
    padding-top: var(--block-padding-top);
    padding-bottom: var(--block-padding-bottom);

    .header {
        letter-spacing: var(--text-letter-spacing);

        @include media("<small") {
            padding-left: var(--extra-mobile-padding);
            padding-right: var(--extra-mobile-padding);
        }

        h2 {
            font-size: 0.9rem;
            font-weight: 700;
        }

        h1 {
            font-size: 1.8rem;
            font-weight: 700;
            margin-bottom: 2rem;
        }
    }

    .tech-logos {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

        @include media("<large") {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
        }

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;

            @include media("<large") {
                justify-content: center;
                align-items: center;
            }

            >img {
                object-fit: contain;
                width: 100%;
                height: 100%;
                max-height: 110px;

                @include media("<large") {
                    width: 60%;
                }
            }

            &:nth-child(1) {
                justify-content: left;

                @include media("<large") {
                    justify-content: center;
                    align-items: center;
                }
            }

            &:nth-child(6) {
                justify-content: right;

                @include media("<large") {
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .img-grayscale {
            filter: grayscale(1);
        }
    }
}
