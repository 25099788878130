.logo-showcase-block {
    .block-padding-top & {
        padding-top: 3rem;
    }

    .block-padding-bottom & {
        padding-bottom: 3rem;
    }

    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    position: relative;

    @include media("<=small") {
        flex-direction: column;
    }

    > p {
        color: var(--text-color);
        font-weight: 600;
        width: 30%;
        max-width: 14rem;
        margin-right: 2rem;
        font-size: 0.9rem;

        @include media("<=small") {
            width: 100%;
            margin-right: 0;
            margin-bottom: 1rem;
            font-size: 1.2rem;
            padding-left: var(--extra-mobile-padding);
            padding-right: var(--extra-mobile-padding);
            font-weight: 700;
            max-width: 100%;
        }
    }

    .tns-outer {
        z-index: 3;
    }

    .logos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        z-index: 2;

        @include media("<=small") {
            padding-left: var(--extra-mobile-padding);
            padding-right: var(--extra-mobile-padding);
        }

        .logo {
            display: flex !important;
            align-items: center;
            justify-content: center;

            img {
                object-fit: cover;
                user-select: none;
                width: 100px;
            }
        }
    }

    .slider-controls {
        position: absolute;
        bottom: -5rem;
        left: 55%;
        width: 100%;
        transform: translateX(-50%);

        lottie-player {
            transform: rotate(270deg);
            height: 12rem;
        }
    }

    >div {
        width: 100%;
    }

    // tiny slider styles
    .tns-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;

        button {
            display: block;
            width: 0.5rem;
            height: 1rem;
            background: #21688b;
            margin-right: 0.5rem;
            border: 0;
            outline: 0;
            opacity: 0.4;
            transition: opacity 0.1s ease-in-out;

            &.tns-nav-active {
                opacity: 1;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
