.job-posting main {
    .job-title-container {
        @include max-width-container;

        .job-title {
            @include max-width-container-no-padding;

            padding-right: var(--site-outer-gap);
            padding-left: var(--site-outer-gap);
            padding-bottom: 5rem;

            @include media("<small") {
                padding-left: calc(var(--site-outer-gap) + var(--extra-mobile-padding));
                padding-right: calc(var(--site-outer-gap) + var(--extra-mobile-padding));
            }

            >h1 {
                display: inline;
                font-size: var(--page-header-size);
                font-weight: 700;
                color: var(--text-color-dark-lighter);
            }

            >h2 {
                display: inline;
                color: var(--secondary-text-color);
                font-size: 1.9rem;
                font-weight: 500;
            }

            .share-area {
                margin-top: 4rem;
                display: flex;
                flex-direction: column;
                letter-spacing: var(--text-letter-spacing);

                @include media("<small") {
                    margin-top: 3rem;
                }

                h3 {
                    color: var(--text-color-dark-lighter);
                    font-weight: 700;
                    margin-bottom: 0.5rem;
                    padding-left: 0.1rem;
                    font-size: 0.8rem;
                }

                .links {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    margin-left: 1px;

                    .link {
                        margin-right: 0.5rem;
                        margin-bottom: 1rem;

                        a {
                            color: var(--text-color-dark-lighter);
                            padding-top: 0.3rem;
                            padding-bottom: 0.3rem;
                            padding-left: 0.5rem;
                            padding-right: 0.5rem;
                            border: solid 0.5px var(--text-color-dark-lighter);
                            font-weight: 600;
                            font-size: 0.7rem;
                            margin-right: 0.5rem;
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }
    }
}
