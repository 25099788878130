@mixin texteditor-default {
    letter-spacing: var(--text-letter-spacing);

    strong {
        font-weight: bold;
    }

    p {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul,
    ol {
        li {
            margin-bottom: 1.4rem;

            @include media("<small") {
                margin-bottom: 0.6rem;
                margin-left: 0;
            }
        }
    }

    ul li {
        position: relative;
        padding-left: 1rem;
        list-style: none;

        &::before {
            position: absolute;
            content: "";
            background-color: var(--secondary-bg-color);
            height: 0.75rem;
            width: 5px;
            user-select: none;
            left: 0;
            top: 0.32rem;
        }
    }

    ol li {
        list-style: decimal;
    }

    i {
        font-style: italic;
    }

    sub {
        vertical-align: sub;
        font-size: smaller;
        font-size: 0.7rem;
    }

    sup {
        vertical-align: super;
        font-size: smaller;
        font-size: 0.7rem;
    }

    a {
        text-decoration: underline;
    }
}
