.text-images-block {
    .block-padding-top & {
        padding-top: var(--block-padding-top);
    }

    .block-padding-bottom & {
        padding-bottom: var(--block-padding-bottom);
    }

    display: flex;
    flex-direction: row;
    color: var(--text-color);

    @include media("<small") {
        flex-direction: column;
    }

    .text-container {
        width: 50%;
        padding-right: 3rem;

        @include media("<small") {
            width: 100%;
            padding-right: 0;
        }

        .text {
            @include texteditor-default;

            max-width: 1000px;
            color: var(--text-color-lighter);
            font-weight: 500;
            margin-bottom: 2rem;

            @include media("<small") {
                padding-left: var(--extra-mobile-padding);
                padding-right: var(--extra-mobile-padding);
            }
        }
    }

    .images-container {
        display: flex;
        position: relative;
        width: 50%;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @include media("<small") {
            width: 100%;
        }

        @include media("<small") {
            padding-left: var(--extra-mobile-padding);
            padding-right: var(--extra-mobile-padding);
        }

        .image-container {
            width: 100%;

            .image {
                display: block;
                object-fit: cover;
                user-select: none;
                width: 100%;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .slider-controls {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;

            .btn {
                background-color: transparent;
                border: none;
                font-size: 1rem;
                color: #b1b0b0;
                font-weight: 200;
            }

            .prev {
                position: absolute;
                left: -50%;

                @include media("<small") {
                    left: calc(-50% + var(--extra-mobile-padding));
                }
            }

            .next {
                position: absolute;
                right: 50%;

                @include media("<small") {
                    right: calc(50% + var(--extra-mobile-padding));
                }
            }
        }
    }
}
