.text-block {
    .block-padding-top & {
        padding-top: var(--block-padding-top);
    }

    .block-padding-bottom & {
        padding-bottom: var(--block-padding-bottom);
    }

    color: var(--text-color);

    .text {
        @include texteditor-default;

        max-width: 1000px;
        color: var(--text-color-lighter);
        font-weight: 500;
        letter-spacing: var(--text-letter-spacing);

        @include media("<small") {
            padding-left: var(--extra-mobile-padding);
            padding-right: var(--extra-mobile-padding);
        }
    }
}
