.content-block {
    @include max-width-container;

    background: var(--background-color);

    .inner-content-block {
        @include max-width-container-inner;
    }

    .inner-content-block-no-padding {
        @include max-width-container-no-padding;
    }

    @media only screen and (min-width: $site-max-width) {
        .inner-content-block-no-padding {
            padding-left: var(--site-outer-gap);
            padding-right: var(--site-outer-gap);
        }
    }

    &.dark-block {
        --text-color: var(--text-color-light);
        --reverse-text-color: var(--text-color-dark);
        --background-color: var(--dark-color);
        --reverse-background-color: var(--light-color);
        --text-color-lighter: var(--text-color-light);
    }

    &.light-block {
        --text-color: var(--text-color-dark);
        --reverse-text-color: var(--text-color-light);
        --background-color: var(--light-color);
        --reverse-background-color: var(--dark-color);
        --text-color-lighter: var(--text-color-dark-lighter);
    }

    .block-padding-top {
        padding-top: var(--block-padding-top);
    }

    .block-padding-bottom {
        padding-bottom: var(--block-padding-bottom);
    }
}
