.multi-text-block {
    .block-padding-top & {
        padding-top: var(--block-padding-top);
    }

    .block-padding-bottom & {
        padding-bottom: var(--block-padding-bottom);
    }

    color: var(--text-color);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 7rem;
    row-gap: 4rem;

    @include media(">small", "<large") {
        grid-template-columns: 1fr 1fr;
        column-gap: 4rem;
    }

    @include media("<=small") {
        grid-template-columns: 1fr;
    }

    .single-text {
        @include media("<small") {
            padding-left: var(--extra-mobile-padding);
            padding-right: var(--extra-mobile-padding);
        }

        h1 {
            color: #21688b;
            font-size: 1.8rem;
            font-weight: 700;
            margin-bottom: 1rem;
            letter-spacing: 0.05rem;
        }

        p {
            font-weight: 200;
            font-size: 0.9rem;
        }
    }
}
