body.about-page > main {
    .content-container {
        @include max-width-container;

        .content {
            @include max-width-container-no-padding;

            padding-left: var(--site-outer-gap);
            padding-right: var(--site-outer-gap);
            padding-top: 4rem;
            padding-bottom: 4rem;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            column-gap: 4rem;
            line-height: 1.5rem;

            .contact-box {
                padding: 2.5rem;
                background-color: black;
                color: var(--text-color-light);
                flex-shrink: 0;
                white-space: nowrap;
                font-family: Montserrat, sans-serif;
                font-weight: 500;

                @include texteditor-default;

                @include media(">1000px") {
                    position: sticky;
                    top: var(--site-outer-gap);
                }

                @include media("<medium") {
                    padding: 1.5rem;
                }

                @include media("<small") {
                    padding: 1rem;
                }
            }

            .text-box {
                @include texteditor-default;
            }
        }
    }

    @include media("<=1000px") {
        .filler {
            padding-bottom: 0;
        }

        .content-container {
            .content {
                flex-direction: column;
                padding-left: 0;
                padding-right: 0;

                .contact-box {
                    padding-left: calc(var(--site-outer-gap) + var(--extra-mobile-padding));
                    padding-right: calc(var(--site-outer-gap) + var(--extra-mobile-padding));
                    margin-bottom: 3rem;
                    white-space: normal;
                    width: 100%;
                }

                .text-box {
                    padding-left: calc(var(--site-outer-gap) + var(--extra-mobile-padding));
                    padding-right: calc(var(--site-outer-gap) + var(--extra-mobile-padding));
                }
            }
        }
    }
}
