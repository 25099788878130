@import "fonts";
@import "helpers/media-queries";
@import "variables";
@import "helpers/reset";
@import "helpers/max-width-container";
@import "helpers/texteditor-text";
@import "base";
@import "header";
@import "footer";
@import "error-pages/error";
@import "error-pages/error404";

// @import 'navigation-menu';
@import "parts/header-menu";
@import "parts/various_headers.scss";
@import "about-page";
@import "job-posting";
@import "page-header";
@import "reference-page";
@import "techpreview";
@import "blocks/simple-text-block";
@import "blocks/text-block";
@import "blocks/logo-showcase";
@import "blocks/multi-text";
@import "blocks/limage-text";
@import "blocks/text-cards";
@import "blocks/teaser-cards";
@import "blocks/base-block";
@import "blocks/text-images";

// importing font awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~tiny-slider/dist/tiny-slider.css";
