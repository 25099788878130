.reference-page {
    .reference:nth-child(odd) {
        .text-images-block {
            flex-direction: row-reverse;

            @include media("<small") {
                flex-direction: column;
            }

            .text-container {
                padding-right: 0;
                padding-left: 3rem;

                @include media("<small") {
                    padding-left: 0;
                }
            }
        }
    }

    .pagination-container {
        .pagination {
            display: flex;
            width: 100%;
            height: 4rem;
            justify-content: center;
            align-items: center;

            li {
                font-size: 2rem;
            }

            li:nth-child(2) {
                margin-left: 3rem;
            }
        }
    }

    .dark-block + .pagination-container {
        background-color: var(--dark-color);
        color: var(--text-color-light);
    }

    .light-block + .pagination-container {
        background-color: var(--light-color);
        color: var(--text-color-dark);
    }
}
