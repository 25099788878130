$bg-color: #232323;
$text-color: white;

.error-page > main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $bg-color;
    color: $text-color;

    .container {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 1600px;
        overflow: hidden;

        // @include media(">small", "<medium") {
        //     // padding: 3rem;
        //     flex-direction: column-reverse;
        //     justify-content: flex-end;
        // }

        @include media("<medium") {
            @media screen and (orientation: portrait) {
                flex-direction: column-reverse;
                justify-content: flex-end;
            }
        }

        .text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-left: 4rem;

            @include media(">=large") {
                margin-right: 8rem;
            }

            // @include media(">small", "<large") {
            //     align-items: flex-start;
            // }

            @include media("<medium") {
                @media screen and (orientation: portrait) {
                    align-items: flex-start;
                    padding-left: 4rem;
                    padding-right: 4rem;
                }
            }

            .header {
                font-size: 2rem;
                font-weight: 200;
                font-family: "Open San", sans-serif;
            }

            .text {
                margin-bottom: 2rem;
            }

            .back-btn {
                display: inline-block;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                padding-left: 1rem;
                padding-right: 1rem;
                width: auto;
                border: solid 1px;
                font-size: 1rem;
                font-weight: 700;
                border-radius: 5px;
                background-color: $text-color;
                color: $bg-color;
                align-self: center;
            }
        }

        .animation-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30rem;

            @include media("<medium") {
                @media screen and (orientation: portrait) {
                    width: 100%;
                    max-width: 300px;
                }
            }

            lottie-player {
                height: 100%;
                width: 100%;
            }
        }
    }
}
