.text-cards-block {
    .block-padding-top & {
        padding-top: var(--block-padding-top);
    }

    .block-padding-bottom & {
        padding-bottom: var(--block-padding-bottom);
    }

    color: var(--text-color);
    display: flex;
    flex-direction: row;

    @include media("<=large") {
        flex-direction: column;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    > h1 {
        font-size: 2rem;
        font-weight: bold;
        white-space: nowrap;
        margin-right: 3rem;

        @include media("<large") {
            padding-bottom: 2rem;
        }

        @include media("<=small") {
            padding-left: var(--extra-mobile-padding);
            padding-right: var(--extra-mobile-padding);
        }
    }

    .text-cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        grid-row: auto;
        gap: 2.5rem;
        margin-left: clamp(2rem, 3vw, 8rem);
        margin-right: 0;

        @include media("<large") {
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
            margin-left: 0;
            gap: 1.5rem;
        }

        @include media("<small") {
            // grid-template-columns: 1fr;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        }

        .text-card {
            background: var(--reverse-background-color);
            color: var(--reverse-text-color);
            border: 2px solid;
            border-color: var(--reverse-background-color);
            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 3rem;
            padding-bottom: 4rem;

            @include media("<small") {
                padding-left: 1rem;
                padding-right: 1rem;
                padding-top: 2rem;
                padding-bottom: 2rem;
            }

            h1 {
                font-weight: 700;
                font-size: 1.3rem;
                margin-bottom: 2rem;
                font-family: "Open Sans", sans-serif;

                @include media("<large") {
                    margin-bottom: 1rem;
                }
            }

            p {
                margin-bottom: 2rem;
            }
        }
    }
}
