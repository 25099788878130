.header-with-addition-container {
    margin-bottom: 2rem;
    line-height: 1.2;

    @include media("<small") {
        padding-left: var(--extra-mobile-padding);
        padding-right: var(--extra-mobile-padding);
    }

    h1 {
        color: var(--text-color-lighter);
        font-size: var(--page-header-size);
        font-weight: 700;
        line-height: 1;
    }

    h2 {
        color: var(--text-color-lighter);
        font-size: 0.9rem;
        letter-spacing: 0;
        font-weight: 700;
        margin-bottom: 0.7rem;
    }

    h3 {
        color: #21688b;
        font-size: var(--page-header-size);
        font-weight: 700;
    }
}

.simple-header-container {
    margin-bottom: 2rem;

    h1 {
        font-size: 2rem;
        font-weight: 700;

        @include media("<small") {
            padding-left: var(--extra-mobile-padding);
            padding-right: var(--extra-mobile-padding);
        }
    }

    h2 {
        font-weight: 700;
        letter-spacing: 0;
        font-size: 0.9rem;

        @include media("<small") {
            padding-left: var(--extra-mobile-padding);
            padding-right: var(--extra-mobile-padding);
        }
    }
}
