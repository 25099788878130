*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    font-size: var(--global-font-size);
}

body {
    // background-color: $main-bg-color;
    // color: $main-font-color;
    // font-family: $main-font-family;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: var(--text-letter-spacing);
    background-color: white;
    font-family: "Open Sans", sans-serif;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    main {
        flex: 1;
    }
}

img {
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
    color: inherit;
}

a.logo {
    display: block;
    max-width: 130px;
    height: auto;

    > svg {
        width: 100%;
    }
}

h1,
h2,
h3,
h4,
h5 {
    hyphens: auto;
    font-family: Montserrat, sans-serif;
}

br {
    display: inline-block;
}

p {
    letter-spacing: var(--text-letter-spacing);
}
