@import "variables";

footer {
    @include max-width-container;

    background-color: #21688b;

    .footer-inner {
        @include max-width-container-inner;

        color: var(--text-color-light);
        display: flex;
        justify-content: space-between;
        height: auto;
        width: 100%;
        padding-left: var(--site-outer-gap);
        padding-right: var(--site-outer-gap);
        padding-top: var(--block-padding-top);
        padding-bottom: var(--block-padding-bottom);
        font-size: 0.8rem;

        @include media("<=small") {
            flex-direction: column;
            line-height: 1.4rem;
            padding-left: calc(var(--site-outer-gap) + var(--extra-mobile-padding));
            padding-right: calc(var(--site-outer-gap) + var(--extra-mobile-padding));
        }

        .footer-navigation {
            .fnav-link {
                padding-bottom: 0.3rem;

                @include media("<small") {
                    padding-bottom: 0;
                }
            }
        }

        .contact {
            letter-spacing: var(--text-letter-spacing);

            h1 {
                font-size: 2rem;
                letter-spacing: 0.3rem;
                padding-bottom: 1rem;
                font-weight: 700;
            }

            .contact-data {
                line-height: 1.4rem;

                .phone {
                    margin-top: 2rem;
                }

                .what3words-icon {
                    display: flex;
                    align-items: center;

                    svg {
                        width: 0.8rem;
                        height: 0.8rem;
                        margin-right: 0.2rem;
                        display: inline-block;
                        fill: var(--text-color-light);
                        margin-left: -1px;
                    }
                }
            }
        }

        .social {
            .links {
                display: flex;
                justify-content: space-between;

                @include media("<small") {
                    justify-content: flex-start;
                }

                a {
                    font-size: 1.2rem;
                    letter-spacing: var(--text-letter-spacing);

                    @include media("<small") {
                        margin-right: 2rem;
                        font-size: 1.5rem;
                        padding: 0.5rem;
                    }
                }
            }
        }

        .small-header {
            letter-spacing: 0;
            font-size: 1rem;
            font-weight: 700;
            padding-bottom: 1.7rem;
            padding-top: 1rem;

            @include media("<small") {
                padding-bottom: 0.5rem;
                padding-top: 3rem;
            }
        }
    }
}
