.simple-text-block {
    .block-padding-top & {
        padding-top: var(--block-padding-top);
    }

    .block-padding-bottom & {
        padding-bottom: var(--block-padding-bottom);
    }

    color: var(--text-color);

    .title {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 3rem;
        letter-spacing: 0.05rem;
    }

    .text {
        @include texteditor-default;

        font-weight: 500;
        max-width: 1000px;
        letter-spacing: var(--text-letter-spacing);
    }

    @include media("<=small") {
        .title {
            font-size: 2rem;
            margin-bottom: 2rem;
        }

        .title,
        .text {
            padding-left: var(--extra-mobile-padding);
            padding-right: var(--extra-mobile-padding);
        }
    }
}

