$breakpoints: (
    "small": 700px,
    "medium": 800px,
    "large": 1200px,
);

:root {
    --site-outer-gap: clamp(2rem, 8vw, 12rem);
    --page-header-size: 3.7rem;
    --site-max-width: 1600px;
    --text-color-light: white;
    --text-color-dark: black;
    --text-color-dark-lighter: #2b2e34;
    --light-color: white;
    --dark-color: black;
    --dark-color-lighter: #1b1e20;
    --secondary-bg-color: #21688b;
    --secondary-text-color: #21688b;
    --navigation-menu-color: #2b2e34;
    --header-height: 5rem;
    --global-font-size: 16px;
    --block-padding-top: 6rem;
    --block-padding-bottom: 6rem;
    --extra-mobile-padding: 1rem;
    --text-letter-spacing: 0.02rem;
    --mobile-font-size: 80%;
}

@include media(">small", "<large") {
    :root {
        --site-outer-gap: clamp(1rem, 5%, 5rem);
        --header-height: 4rem;
        --block-padding-top: 5rem;
        --block-padding-bottom: 5rem;
        --page-header-size: 3.2rem;
    }
}

@include media("<=small") {
    :root {
        --site-outer-gap: clamp(0.5rem, 2rem, 5%);
        --page-header-size: 2.5rem;
        --header-height: 3rem;
        --block-padding-top: 4rem;
        --block-padding-bottom: 4rem;
        --global-font-size: var(--mobile-font-size);
    }
}

$site-max-width: 1600px;
