@import "variables";

header {
    @include max-width-container;

    width: 100%;

    .header-inner {
        @include max-width-container-no-padding;

        padding-left: var(--site-outer-gap);
        padding-right: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: var(--header-height);
        margin-bottom: 7rem;
        position: relative;

        @include media("<small") {
            padding-left: calc(var(--site-outer-gap) + var(--extra-mobile-padding));
        }

        .logo-corner {
            display: flex;
            align-items: center;
            margin-top: 2rem;

            @include media("<small") {
                flex-direction: column;
                margin-top: 1rem;
                align-items: flex-start;
                justify-content: flex-end;
            }

            img {
                height: 2.5rem;
                margin-right: 2rem;
            }

            .logo-url {
                height: 2.5rem;

                @include media("<small") {
                    margin-bottom: 1rem;
                }
            }

            .header-btn {
                align-self: center;
                background-color: var(--dark-color);
                color: var(--text-color-light);
                border-radius: 6px;
                font-size: 0.6rem;
                font-weight: bold;
                letter-spacing: 0;
                padding-right: 0.8rem;
                padding-left: 0.8rem;
                padding-top: 0.4rem;
                padding-bottom: 0.4rem;

                @include media("<small") {
                    align-self: flex-start;
                }
            }
        }
    }
}
