.page-header-container {
    @include max-width-container;
}

.page-header {
    @include max-width-container-inner;

    line-height: 1.2;

    h1 {
        font-size: var(--page-header-size);
        font-weight: 700;
        color: var(--text-color-dark-lighter);
    }

    h2 {
        font-size: 0.9rem;
        letter-spacing: 0;
        font-weight: 700;
        color: var(--text-color-dark-lighter);
        margin-bottom: 0.7rem;
    }

    .header-addition {
        // margin-top: 0.5rem;
        color: #21688b;
        font-size: var(--page-header-size);
        font-weight: 700;
    }

    .link {
        display: inline-block;
        background: var(--dark-color);
        color: var(--text-color-light);
        font-size: 0.8rem;
        font-weight: 700;
        letter-spacing: 0;
        border-radius: 20px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-top: 1rem;
    }

    .filler {
        padding-bottom: 8rem;

        @include media("<small") {
            padding-bottom: 3rem;
        }
    }

    .seperator {
        height: 1px;
        background-color: var(--dark-color);
    }

    @include media("<=small") {
        h1,
        h2,
        h3 {
            padding-left: var(--extra-mobile-padding);
            padding-right: var(--extra-mobile-padding);
        }

        .link,
        .filler,
        .seperator {
            margin-left: var(--extra-mobile-padding);
            margin-right: var(--extra-mobile-padding);
        }
    }
}
